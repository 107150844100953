import { firestore } from "../firebase"

export const fetchBlog = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const snapshot = await firestore
				.collection("articles")
				.orderBy("date", "desc")
				.get()

			let snapshotsData = snapshot.docs.map(doc => doc.data());

			snapshotsData = snapshotsData.map(snap => {
				return {
					...snap,
					link: (snap.title[0] === " " ? snap.title.substring(1) : snap.title).split(" ").map(word => word.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '')).join("-")
				};
			})
			resolve(snapshotsData)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})
}

export const getArticles = state => state.blog

export const getArticle = (state, link) => state.blog.filter(blog => blog.link === link)[0];

const blogReducer = (state = [], action) => {
	const { type, blog } = action

	switch (type) {
		case "SET_BLOG":
			return blog

		default:
			return state
	}
}

export default blogReducer
