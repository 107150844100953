import React from "react"
import "../style/Loading.scss"

function Loading() {
  return (
    <div className="loading">
      <img src="/main_logo.png" alt="xposed_logo" />
    </div>
  )
}

export default Loading
