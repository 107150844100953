import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ProjectItem, TextSection } from "../components";
import "../style/ProjectsList.scss";

function ProjectsList({ projects, sectionData, serviceId }) {
  const history = useHistory();

  const ignore_titles =
    history.location.pathname == "/resto-site"
      ? []
      : ["Fine Dining", "Coffee Shop", "Food Shop"];

  return (
    <div className="projects">
      {sectionData && <TextSection data={sectionData} />}

      <div className="projects__container">
        {projects
          .filter((it) => !ignore_titles.includes(it.title))
          .map((project) => (
            <Fragment key={project.title}>
              <ProjectItem project={project} serviceId={serviceId} />
            </Fragment>
          ))}
      </div>
    </div>
  );
}

export default ProjectsList;
