import { firestore } from "../firebase";

export const fetchServices = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await firestore
        .collection("services")
        .orderBy("sort")
        .get();

      const snapshotsData = snapshot.docs.map((doc) => doc.data());

      resolve(snapshotsData);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getServices = (state, lang) => {
  var arr = [];
  for (var i = 0; i < state.services.length; i++) {
    if (state.services[i].id !== "resto-site")
      arr.push(state.services[i][lang]);
  }
  return arr;
};

export const getService = (state, id, lang) => {
  const services = getServices(state, lang);
  return services.find((s) => s.id === id);
};

const servicesReducer = (state = [], action) => {
  const { type, services } = action;

  switch (type) {
    case "SET_SERVICES":
      return services;

    default:
      return state;
  }
};

export default servicesReducer;
