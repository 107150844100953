import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import {
  ContactSection,
  PageLanding,
  ProjectsList,
  SectionsList,
  ServicesSection,
} from "../components";
import { getProjects, getLanguages } from "../redux";
import { useEffect, useState } from "react";

import {
  useService,
  useTextWithLanguage,
} from "../hooks/useSelectorWithLanguage";
import "../style/Service.scss";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../components/DropdownComponent";
import { useHistory } from "react-router-dom";

function Service() {
  const { t, i18n } = useTranslation();
  const serviceId = useLocation().pathname.slice(1);
  const projects = useSelector((state) => getProjects(state, serviceId));
  const service = useService(serviceId);
  const servicePaths = useTextWithLanguage("servicePaths");
  const languages = useSelector((state) => getLanguages(state));
  const [dropdownVisible, setDropdownVisible] = useState(true);

  const history = useHistory();
  const goToContact = () => {
    history.push("/contact");
  };

  useEffect(() => {
    const handleScroll = () => {
      const restoBtn = document.querySelector(".floatingBtn");
      if (restoBtn != null) {
        if (window.pageYOffset >= window.innerHeight - 75) {
          restoBtn.style.top = "120px";
          setDropdownVisible(false);
        } else {
          restoBtn.style.top = "40px";
          setDropdownVisible(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!service) {
    return <Redirect to={"/404"} />;
  }

  const { background, title, description, callToAction } = service;
  return (
    <div className="service">
      <Helmet>
        <title>{servicePaths[serviceId]["title"]} - Xposed Agency</title>
        <link rel="canonical" href={`https://www.xposed.ro/${serviceId}`} />
        <meta
          name="description"
          content={servicePaths[serviceId]["description"]}
        />
        <meta name="keywords" content={servicePaths[serviceId]["keyword"]} />
      </Helmet>
      <div
        className="changeLang"
        style={{ visibility: dropdownVisible ? "visible" : "hidden" }}
      >
        <DropdownComponent items={languages} selected={i18n.resolvedLanguage} />
      </div>
      <div
        className="floatingBtn"
        style={{ visibility: "visible", top: "40px" }}
        onClick={() => goToContact()}
      >
        {t("button.contactus")}
      </div>
      <PageLanding
        src={background}
        title={title}
        description={description}
        projects={projects}
        sectionData={service.secondaryDescription}
        languageVisible={!dropdownVisible}
        serviceId={serviceId}
        callToAction={callToAction}
      />

      <ProjectsList
        projects={projects}
        sectionData={service.secondaryDescription}
        serviceId={serviceId}
      />

      <ServicesSection
        services={service.approaches}
        mainDescription={service.approachesDescription}
        approachesTitle={service.approachesTitle}
        serviceId={serviceId}
        benefits={service.approachesBenefits}
      />

      <ContactSection serviceId={serviceId} />

      <SectionsList
        isAspectRatio
        services={service.solutions || []}
        text={service.solutionsText}
        type="solutions"
        noFindMore
        serviceId={serviceId}
      />
    </div>
  );
}

export default Service;
