import { firestore } from "../firebase"

export const fetchTemplates = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const snapshot = await firestore
				.collection("templates")
                .orderBy("sort")
				.get()
			const snapshotsData = snapshot.docs.map(doc => doc.data())
			resolve(snapshotsData)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})
}

export const getTemplates = state => {
	return state.templates
}

export const getTemplate = (state, id) => {
	return state.templates.find(s => s.id === id)
}



const templatesReducer = (state = [], action) => {
	const { type, templates } = action
	switch (type) {
		case "SET_TEMPLATES":
			return templates
		default:
			return state
	}
}

export default templatesReducer
