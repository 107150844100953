import "../style/Home.scss"
import {
	ContactSection,
	PageLanding,
	ProjectsList,
	SectionsList,
} from "../components"
import { useSelector } from "react-redux"
import { getProjects, getLanguages } from "../redux"
import { useServices} from "../hooks/useSelectorWithLanguage";
import { Helmet } from "react-helmet"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import {useTextWithLanguage} from "../hooks/useSelectorWithLanguage";
import DropdownComponent from "../components/DropdownComponent"

function Home() {

	const { t, i18n } = useTranslation()
	const history = useHistory();
	const projects = useSelector(getProjects)
	const services = useServices()
	const sectionData = useTextWithLanguage('video_intl')
	const sectionData2 = useTextWithLanguage('portofolio_intl')
	const languages = useSelector((state => getLanguages(state)))
	const [dropdownVisible, setDropdownVisible] = useState(true)

	const goToContact = () => {
		history.push("/contact");
	}

	useEffect(() => {
		const handleScroll = () => {
			const restoBtn = document.querySelector(".floatingBtn")
			if(restoBtn != null)
				if (window.pageYOffset >= window.innerHeight - 75) {
					restoBtn.style.top = "120px"
					setDropdownVisible(false)
				} else {
					restoBtn.style.top = "40px"
					setDropdownVisible(true)
				}
		}
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])




	return (
		<div className="home">
			<Helmet>
				<title>
					{t('mainTitle.title')}
				</title>
				<link rel="canonical" href="https://www.xposed.ro" />
				<meta name="description"
					content="Expunem imaginea ta in mediul virtual: Creare Aplicatii Mobile, Aplicatii Web, CreareMagazin Online, Promovare Online, Copywriting, Strategie Marketing. " />
				<meta name="keywords" content="creare site,creare magazin online,site de prezentare,web design,dezvoltare aplicatii mobile,programator aplicatii mobile,aplicatii mobile,aplicatii iOS,aplicatii android,creare aplicatii mobile,strategie marketing aplicatii mobile,design aplicatii Android,creare aplicatii Android,dezvoltare aplicatii iOS,design aplicatii iOS,marketingul aplicatilor mobile,marketing aplicatii mobile,promovare aplicatii mobile,design aplicatii mobile" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
				{/*share meta tags*/}
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Agentie de Web Design | Dezvoltare Aplicatii Mobile - Xposed Agency" />
				<meta property="og:description" content="Expunem imaginea ta in mediul virtual prin serviciile noastre de Creare Site, Creare Magazin Online, Dezvoltare Aplicatii Mobile si Promovare Online. Lucram doar cu cele mai moderne tehnologii. Iti vom livra cele mai rapide site-uri de pe piata." />
				<meta property="og:site_name" content="Xposed Agency" />
				<meta property="og:image" content='/main_logo.png' />
			</Helmet>
			
			<div className="changeLang" style={{ visibility: dropdownVisible ? "visible" : "hidden" }}>
				<DropdownComponent items = {languages} selected = {i18n.resolvedLanguage} />
			</div>
			<div className="floatingBtn" style={{'visibility': 'visible', 'top': '40px'}} onClick={() => goToContact()}>
				{t('button.contactus')}
			</div>

			<PageLanding
				src={sectionData.src}
				title={sectionData.title}
				description={sectionData.description}
				languageVisible={!dropdownVisible}
				isVideo
			/>

			<ProjectsList projects={projects} sectionData={sectionData2} />

			<ContactSection />

			<SectionsList isAspectRatio services={services} type="services_intl" />
		</div>
	)
}

export default Home
