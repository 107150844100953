import { firestore } from "../firebase"
import { useTranslation, Trans } from 'react-i18next'



export const fetchText = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const snapshot = await firestore.collection("text").doc("main").get()

			const snapshotsData = snapshot.data()

			resolve(snapshotsData)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})
}

export const getTexts = state => {
	return state.text
}

export const getLanguages = (state) => {
	return state.text['languages']
}

export const getText = (state, id, lang, isBlog = false) => {

	if(lang == null || id == undefined || isBlog)
		return state.text[id]

	return state.text[id][lang]
}

const textReducer = (state = {}, action) => {
	const { type, text } = action

	switch (type) {
		case "SET_TEXT":
			return text

		default:
			return state
	}
}

export default textReducer
