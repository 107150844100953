export const getCookie = state => state.cookie

const cookieReducer = (state = document.cookie, action) => {
	const { type, cookie } = action

	switch (type) {
		case "SET_COOKIE":
			document.cookie = `policy=${JSON.stringify(cookie)}; Secure`
			return document.cookie

		default:
			return state
	}
}

export default cookieReducer
