import { useSelector } from "react-redux"
import SunEditor from "suneditor-react"
import { useForm } from "react-better-hook-form"
import { useState } from "react"
import moment from "moment"

import { AdminSignin } from "../components"
import { getAdmin } from "../redux"

import { firestore, storage } from "../firebase"
import { useHistory } from "react-router-dom"

import "suneditor/dist/css/suneditor.min.css"
import "../style/Admin.scss"
import { Helmet } from "react-helmet"

function AddArticle() {
  const { handleChange, data } = useForm({})
  const [content, setContent] = useState("")
  const [image, setImage] = useState()

  const admin = useSelector(getAdmin)

  const history = useHistory()

  if (!admin) return <AdminSignin />

  const handleImage = ev => setImage(ev.target.files[0])

  const handleSubmit = async ev => {
    ev.preventDefault()
    const newArticle = { ...data, content }
    try {
      const newDoc = firestore.collection("articles").doc(data.title)

      const refresh = () => history.go(0)

      if (data.date) {
        const dateString = data.date

        const dateMomentObject = moment(dateString, "DD.MM.YYYY")
        const dateObject = dateMomentObject.toDate()
        newArticle.date = dateObject
      } else {
        newArticle.date = Date.now()
      }

      if (!image) return newDoc.set(newArticle).then(refresh)

      const imageChild = storage.child("articles/" + data.title)
      const uploadImage = imageChild.put(image)

      uploadImage.on(
        "state_changed",
        undefined,
        err => {},
        () => {
          uploadImage.snapshot.ref
            .getDownloadURL()
            .then(downloadURL =>
              newDoc.set({ ...newArticle, image: downloadURL }).then(refresh)
            )
        }
      )
    } catch (err) {}
  }

  return (
    <div className="admin__createArticle">
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>

      <form className="contactpage__form" onSubmit={handleSubmit}>
        <h1>Articol nou</h1>

        <input onChange={handleChange} name="title" placeholder="Titlu" />

        <input onChange={handleChange} name="author" placeholder="Autor" />

        <input onChange={handleChange} name="tags" placeholder="Tag-uri" />
        <input onChange={handleChange} name="description" placeholder="Descriere" />

        <input
          onChange={handleChange}
          name="date"
          placeholder="Data(optional)"
        />

        <SunEditor
          onChange={setContent}
          setDefaultStyle="font-family: Gotham A, Gotham B; font-size: 20px;"
        />

        <input type="file" onChange={handleImage} accept="image/*" />

        {image && <img src={URL.createObjectURL(image)} alt="preview" />}

        {/* <div>{ReactHtmlParser(content)}</div> */}

        <button type="submit">Trimite</button>
      </form>
    </div>
  )
}

export default AddArticle
