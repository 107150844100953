import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TextSection } from "../components";
import { getText } from "../redux";
import "../style/SectionsList.scss";
import { splitText } from "../utils";
import { useTranslation } from "react-i18next";
import { useTextWithLanguage } from "../hooks/useSelectorWithLanguage";

function SectionsList({
  services,
  type,
  noFindMore,
  text,
  isAspectRatio,
  isBlog = false,
  serviceId,
}) {
  const sectionData = useTextWithLanguage(type, (isBlog = { isBlog }));
  const { t, i18n } = useTranslation();

  const servicesList = services.map((service, index) => {
    const { id, title, picture, image, description, color } = service;
    const noImage =
      "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2Fno-image.png?alt=media&token=1af8f87f-3c94-41e8-8661-478c9f7975c1";
    const toPath =
      type === "articles"
        ? `/blog/${(title[0] === " " ? title.substring(1) : title)
            .split(" ")
            .map((word) =>
              word.toLowerCase().normalize("NFKD").replace(/[^\w]/g, "")
            )
            .join("-")}`
        : id || "#";

    if (serviceId === "aplicatii-web" || serviceId === "web-applications")
      return (
        <Fragment key={title}>
          <Link
            to={toPath}
            className="services__item-new"
            style={{ backgroundColor: color }}
          >
            <div className="image-container">
              <div
                style={{
                  backgroundImage: `url(${picture || image || noImage})`,
                }}
                className="image"
              />
            </div>

            <h3> {title}</h3>

            <p>{description}</p>
          </Link>
        </Fragment>
      );

    return (
      <Fragment key={title}>
        <Link to={toPath} className="services__item__link">
          <div className="services__item">
            {isAspectRatio ? (
              <div className="services__item__wrapper">
                <img
                  src={picture || image || noImage}
                  alt={title}
                  loading="lazy"
                />
              </div>
            ) : (
              <img
                className="services__item__blogImg"
                src={picture || image || noImage}
                alt={title}
                loading="lazy"
              />
            )}
            <h3 className="services__item__title">{title}</h3>
            <p className="services__item__description">
              {splitText(description)}
            </p>
            {!noFindMore && (
              <div className="services__item__more">{t("findoutMore")}</div>
            )}
          </div>
        </Link>
      </Fragment>
    );
  });

  return (
    <div className="services">
      {sectionData && <TextSection data={text || sectionData} />}

      <div className="services__container">{servicesList}</div>
    </div>
  );
}

export default SectionsList;
