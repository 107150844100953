import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { HamburgerMenu } from "../components";
import { useServices } from "../hooks/useSelectorWithLanguage";
import { getLanguages } from "../redux";
import "../style/Navbar.scss";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../components/DropdownComponent";

function Navbar({ languageVisible }) {
  const { t, i18n } = useTranslation();
  const [displayMenu, setDisplayMenu] = useState(false);
  const services = useServices();
  const serviceId = useLocation().pathname.slice(1);
  const languages = useSelector((state) => getLanguages(state));

  useEffect(() => {
    const handleResise = () => {
      const btn1 = document.querySelector("#id-4");
      // const btn2 = document.querySelector("#id-5");
      let width = window.innerWidth;

      // if (width < 700) {
      //   btn2.style.display = "none";
      // } else {
      //   btn2.style.display = "flex";
      // }

      if (width < 500) {
        btn1.style.display = "none";
      } else {
        btn1.style.display = "flex";
      }
    };

    const handleScroll = () => {
      const menu = document.querySelector(".hamburgerButton");
      const navbar = document.querySelector(".navbar");

      if (!menu || !navbar) return;

      if (window.pageYOffset >= window.innerHeight - 75) {
        menu.style.display = "none";
        navbar.style.position = "fixed";
        navbar.style.top = "0px";

        return setDisplayMenu(true);
      }

      menu.style.display = "block";
      navbar.style.position = "static";

      setDisplayMenu(false);
    };

    window.addEventListener("resize", handleResise);
    window.addEventListener("scroll", handleScroll);

    handleResise();

    return () => {
      window.removeEventListener("resize", handleResise);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const _filter_suspend = (service) => {
    return !t("suspended_services").includes(service.title);
  };

  const checkNavTitle = (text) => {
    if (text === "Obtine aplicatii web remarcabile") return "Aplicatii Web";
    if (text === "Get outstanding web applications") return "Web Applications";

    return text;
  };

  const navItemsList = () => {
    const list = services.filter(_filter_suspend).map((s, idx) => (
      <Link
        id={`id-${idx}`}
        className="navbar__item"
        to={`/${s.id}`}
        key={s.id}
        style={{ background: s.id === serviceId ? "#35bdf6" : undefined }}
      >
        {checkNavTitle(s.title)}
      </Link>
    ));
    return list;
  };

  return (
    <div className="navbar">
      {navItemsList()}
      {languageVisible && (
        <div className="navbar__item" style={{ width: "52px" }}>
          <DropdownComponent
            items={languages}
            selected={i18n.resolvedLanguage}
          />
        </div>
      )}
      {displayMenu ? <HamburgerMenu /> : <></>}
    </div>
  );
}

export default Navbar;
