import { useState } from "react";
import { useTextWithLanguage } from "../hooks/useSelectorWithLanguage";
import { mdiFacebook, mdiInstagram, mdiLinkedin } from "@mdi/js";
import Icon from "@mdi/react";
import emailjs from "emailjs-com";
import { useForm } from "react-better-hook-form";
import { useHistory } from "react-router-dom";

export default function FooterContact() {
  const sectionData = useTextWithLanguage("contact_intl");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { handleChange, checkErrors, errors, data } = useForm({
    default: {},
    reqs: {
      name: {
        required: true,
        max: { value: 256 },
      },
      email: {
        required: true,
        email: true,
        max: { value: 256 },
      },
      phone: {
        required: true,
        max: { value: 15 },
      },
      message: {
        required: true,
        min: { value: 10 },
        max: { value: 2000 },
      },
    },
  });
  const history = useHistory();

  const handleInputChange = (ev) => {
    handleChange(ev);
    ev.target.style.border = "1px solid #ccc";
    setSuccess(false);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const nameInput = document.querySelector("#formName");
    const emailInput = document.querySelector("#formEmail");
    const phoneInput = document.querySelector("#formPhone");
    const messageInput = document.querySelector("#formMessage");

    if (!data.name || errors.name) {
      nameInput.style.border = "2px solid red";
    }
    if (!data.email || errors.email) {
      emailInput.style.border = "2px solid red";
    }
    if (!data.phone || errors.phone) {
      phoneInput.style.border = "2px solid red";
    }
    if (!data.message || errors.message) {
      messageInput.style.border = "2px solid red";
    }

    const errorsExist = checkErrors();
    if (errorsExist) {
      return;
    }

    sendMail(ev);
  };

  const sendMail = (ev) => {
    ev.preventDefault();

    emailjs
      .sendForm(
        "service_zq6xl1s",
        "template_rrung9t",
        ev.target,
        "Pm76v8sCen0yuKP4_"
      )
      .then((result) => {
        setSuccess(true);
        history.push("/success");
      })
      .catch((err) => {
        setSuccess(true);
        setError(
          <p>
            {" "}
            {sectionData.error}{" "}
            <a
              className="contactpage__link"
              target="_blank"
              href="mailto:contact@xposed.ro"
              rel="noreferrer"
            >
              {" "}
              contact@xposed.ro
            </a>
          </p>
        );
        console.error(err);
      });
  };

  return (
    <div className="footer-contact">
      <div className="wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <h2>{sectionData.subtitle}</h2>

          <input
            id="formName"
            type="text"
            name="name"
            placeholder={sectionData.form.formName}
            onChange={handleInputChange}
          />
          <input
            id="formEmail"
            type="email"
            name="email"
            placeholder={sectionData.form.formEmail}
            onChange={handleInputChange}
          />
          <input
            id="formPhone"
            type="tel"
            name="phone"
            placeholder={sectionData.form.formPhone}
            onChange={handleInputChange}
          />
          <textarea
            id="formMessage"
            name="message"
            placeholder={sectionData.form.formMessage}
            rows="4"
            onChange={handleInputChange}
          />

          <button type="submit">{sectionData.form.sendMessage}</button>
          {error && <p className="contactpage__form__error">{error}</p>}
          {success && (
            <p className="contactpage__form__success">
              {sectionData.form.messageOk}
            </p>
          )}
        </form>

        <div className="separator" />

        <div className="socials">
          <h2>{sectionData.secondaryTitle}</h2>

          <span>{sectionData.phone}</span>

          <span>{sectionData.email}</span>

          <div className="icons">
            <a
              href="https://www.facebook.com/xposedagency/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon path={mdiFacebook} size={1.2} color="#333" />
            </a>
            <a
              href="https://www.linkedin.com/company/xposed-agency"
              target="_blank"
              rel="noreferrer"
            >
              <Icon path={mdiLinkedin} size={1.2} color="#333" />
            </a>
            <a
              href="https://www.instagram.com/xposedagency/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon path={mdiInstagram} size={1.2} color="#333" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
