import { Children } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Loading, PageLanding } from "../components"
import { getLanguages } from "../redux"
import "../style/About.scss"
import { splitText } from "../utils"
import {useTextWithLanguage} from "../hooks/useSelectorWithLanguage";
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from "react"
import DropdownComponent from "../components/DropdownComponent"
import { useHistory } from "react-router-dom"

function About() {

	const about = useTextWithLanguage('about_intl');
	const { t, i18n } = useTranslation()
	const [dropdownVisible, setDropdownVisible] = useState(true)
	const languages = useSelector((state => getLanguages(state)))

	const history = useHistory();
	const goToContact = () => {
		history.push("/contact");
	}

	useEffect(() => {
		const handleScroll = () => {

			const restoBtn = document.querySelector(".floatingBtn")
			if(restoBtn != null)
				if (window.pageYOffset >= window.innerHeight - 75) {
					restoBtn.style.top = "120px"
					setDropdownVisible(false)
				} else {
					restoBtn.style.top = "40px"
					setDropdownVisible(true)
				}
		}
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	const membersMap = member => {
		const { image, name, role, sort } = member
		return (
			<div className="about__members__item">
				<img src={image} alt={name} />
				<h4>{name}</h4>
				<p>{role}</p>
			</div>
		)
	}

	const technologiesMap = technology => {
		return <img src={technology} alt="technology" />
	}

	const getMembers = () => {
		const sortedMap = new Map([...about.members.entries()].sort((a, b) => a[1].sort - b[1].sort));
		const membersArray = Array.from(sortedMap, ([, value]) => value);
		return Children.toArray(membersArray.map(membersMap));
	}

	if (!about) return <Loading />

	return (
		<div className="about">
			<Helmet>
				<title>{about.other["title"]}</title>
				<link rel="canonical" href="https://www.xposed.ro/despre-noi" />
				<meta
					name="description"
					content={about.other["title"]}
				/>
				<meta name="keywords" content="xposed, echipa xposed, despre xposed"/>
			</Helmet>

			<div className="changeLang" style={{ visibility: dropdownVisible ? "visible" : "hidden" }}>
				<DropdownComponent items = {languages} selected = {i18n.resolvedLanguage} />
			</div>
			<div className="floatingBtn" 
			style={{'visibility': 'visible', 'top': '40px'}} 
			onClick={() => goToContact()}>
				{t('button.contactus')}
			</div>
			<PageLanding
				src={about.mainImage}
				title={about.title}
				description={about.description}
				languageVisible={!dropdownVisible}
			/>

			<div className="about__section">
				<div className="about__members">
					<div
						className="section__top section__top__about"
						style={{ paddingTop: 50 }}
					>
						<h3>{about.other["ourTeam"]}</h3>

						<div className="section__top__right">
							<p>{splitText(about.teamDescription)}</p>
						</div>
					</div>

					<div className="about__members__items">
						{getMembers()}
					</div>
				</div>

				<div className="section__top section__top__about about__contact">
					<h3>{about?.contact?.subtitle}</h3>

					<div className="section__top__right">
						<h1>{splitText(about?.contact?.title)}</h1>

						<div
							className="pageLanding__text__separator"
							style={{ margin: "20px 0" }}
						/>

						<p>{splitText(about?.contact?.description)}</p>

						<Link to="/contact">{about.other["contactUs"]}</Link>
					</div>
				</div>

				<div className="about__technologies">
					<div
						className="section__top section__top__about"
						style={{ paddingTop: 50 }}
					>
						<h3>{about.other["technologies"]}</h3>
						<div className="section__top__right">
							<p>{splitText(about.teamDescription)}</p>
						</div>
					</div>

					<div className="about__technologies__items">
						{Children.toArray(about.technologies.map(technologiesMap))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
