/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ReactHtmlParser from "react-html-parser"

import "../style/Article.scss"
import { getArticle, getText } from "../redux"
import { PageLanding } from "../components"
import { Helmet } from "react-helmet"
import React from "react";

function Article() {
	const { articleId } = useParams()
	const article = useSelector(state => getArticle(state, articleId))
	const blog = useSelector(state => getText(state, ["blog"]))
	const { title, author, image, content, keywords, description, date } = article
	return (
		<>
			<Helmet>
				<title>{title} - Xposed Agency</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={`articol xposed, ${keywords}`}/>
			</Helmet>

			<PageLanding src={blog.mainImage} title={title} isArticle />
			<div className="article__wrapper">
				<div className="article">
					<div className="article__top">
						<img src={image} alt="article" />

						<div
							className="pageLanding__text__separator"
							style={{ margin: "20px 0" }}
						/>
					</div>
					<div className="article__content">
						{ReactHtmlParser(content + blog["article-outro"])}
					</div>
				</div>
			</div>
		</>
	)
}

export default Article
