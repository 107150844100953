import { combineReducers } from "redux"
import templatesReducer, { getTemplates, fetchTemplates} from './templatesReducer';
import projectsReducer, { getProjects, fetchProjects } from "./projectsReducer"
import blogReducer, { getArticles, getArticle, fetchBlog } from "./blogReducer"
import servicesReducer, {
  getServices,
  fetchServices,
  getService,
} from "./servicesReducer"
import cookieReducer, { getCookie } from "./cookieReducer"
import textReducer, { getTexts, getText, fetchText, getLanguages } from "./textReducer"
import adminReducer, { getAdmin } from "./adminReducer"

export const rootReducer = combineReducers({
  templates: templatesReducer,
  projects: projectsReducer,
  services: servicesReducer,
  cookie: cookieReducer,
  text: textReducer,
  admin: adminReducer,
  blog: blogReducer,
})

export { getProjects, fetchProjects }
export { getServices, getService, fetchServices }
export { getTexts, getText, fetchText, getLanguages }
export { getArticles, getArticle, fetchBlog }
export { getCookie }
export { getAdmin }
export { getTemplates, fetchTemplates }
