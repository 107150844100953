import "../style/PageLanding.scss";
import { HamburgerMenu, Navbar } from "../components";
import { ChevronDownIcon } from "../svg";
import { Link } from "react-router-dom";
import { splitText } from "../utils";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

function PageLanding({
  src,
  isVideo,
  title,
  description,
  languageVisible,
  serviceId,
  callToAction,
}) {
  const ref = useRef();

  useEffect(() => {
    if (isVideo && ref.current) {
      const video = ref.current;
      video.onloadeddata = () => {
        video.play();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const handleScrollButton = (ev) => {
    ev.preventDefault();

    window.scroll(0, window.innerHeight);
  };

  const history = useHistory();
  const goToContact = () => {
    history.push("/contact");
  };

  // useEffect(() => {
  // 	if (isVideo) {
  // 		const video = document.querySelector("video")
  // 		video.onloadeddata = () => {
  // 			video.play()
  // 		}
  // 	}
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <>
      <div className="pageLanding">
        <HamburgerMenu main />

        {isVideo ? (
          <video
            ref={ref}
            muted={true}
            autoPlay={true}
            playsInline={true}
            preload="metadata"
            loop
            src={src}
            controls={false}
          >
            {/*<source src={src} />*/}
          </video>
        ) : src ? (
          <>
            <img src={src} alt="landing" />
            <div className="pageLanding__filter" />
          </>
        ) : (
          <div className="pageLanding__filter" />
        )}

        <div className="pageLanding__details">
          <Link to="/">
            <img src="/main_logo.png" loading="lazy" alt="xposed_logo" />
          </Link>

          <div className="pageLanding__text">
            <h1>{splitText(title)}</h1>

            <div className="pageLanding__text__separator" />

            <p>{splitText(description)}</p>

            {(serviceId === "aplicatii-web" ||
              serviceId === "web-applications") && (
              <div className="pageLanding__text__actions">
                <div onClick={goToContact}>{callToAction}</div>
              </div>
            )}
          </div>

          <div style={{ height: 200, width: "26%" }} />

          <button
            onClick={handleScrollButton}
            style={{ top: window.innerHeight - 75 }}
          >
            <ChevronDownIcon />
          </button>
        </div>
      </div>
      <Navbar languageVisible={languageVisible} />
    </>
  );
}

export default PageLanding;
