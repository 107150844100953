import { Fragment } from "react"
import { DesignItem, TextSection } from "../components"
import "../style/ProjectsList.scss"

function DesignList({ designs, sectionData }) {
	return (
		<div className="projects">
			{sectionData && <TextSection data={sectionData} />}

			<div className="projects__container">
				{designs.map(design => (
					<Fragment key={design.title}>
						<DesignItem design={design} />
					</Fragment>
				))}
			</div>
		</div>
	)
}

export default DesignList
