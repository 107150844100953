import { useForm } from "react-better-hook-form"
import { useDispatch } from "react-redux"

function AdminSignin() {
  const { handleChange, data } = useForm()
  const dispatch = useDispatch()

  const handleSubmit = ev => {
    ev.preventDefault()

    if (data.name !== process.env.REACT_APP_ADMIN_ID) return
    else if (data.password !== process.env.REACT_APP_ADMIN_KEY) return

    dispatch({ type: "SET_ADMIN", admin: data })
  }

  return (
    <div className="admin">
      <form onSubmit={handleSubmit} className="admin__auth">
        <h2>DASHBOARD</h2>

        <input name="name" placeholder="Nume" onChange={handleChange} />
        <input
          type="password"
          name="password"
          placeholder="Parola"
          onChange={handleChange}
        />

        <button type="submit">CONECTEAZA-TE</button>
      </form>
    </div>
  )
}

export default AdminSignin
