import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-better-hook-form";
import { Link } from "react-router-dom";
import { mdiFacebook, mdiInstagram, mdiLinkedin } from "@mdi/js";
import Icon from "@mdi/react";
import emailjs from "emailjs-com";
import "../style/Contact.scss";

import { PageLanding } from "../components";
import { splitText } from "../utils";
import { Helmet } from "react-helmet";
import { useTextWithLanguage } from "../hooks/useSelectorWithLanguage";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../components/DropdownComponent";
import { getLanguages } from "../redux";
import { useHistory } from "react-router-dom";

function Contact() {
  const { t, i18n } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const languages = useSelector((state) => getLanguages(state));

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= window.innerHeight - 75) {
        setDropdownVisible(false);
      } else {
        setDropdownVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { handleChange, checkErrors, errors, data } = useForm({
    default: {},
    reqs: {
      name: {
        required: true,
        max: { value: 256 },
      },
      email: {
        required: true,
        email: true,
        max: { value: 256 },
      },
      phone: {
        required: true,
        max: { value: 15 },
      },
      message: {
        required: true,
        min: { value: 10 },
        max: { value: 2000 },
      },
    },
  });

  const sectionData = useTextWithLanguage("contact_intl");
  const form = sectionData.form;
  const [agreedPolicy, setAgreedPolicy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const nameInput = document.querySelector("#formName");
    const emailInput = document.querySelector("#formEmail");
    const phoneInput = document.querySelector("#formPhone");
    const messageInput = document.querySelector("#formMessage");

    if (!data.name || errors.name) {
      nameInput.style.border = "2px solid red";
    }
    if (!data.email || errors.email) {
      emailInput.style.border = "2px solid red";
    }
    if (!data.phone || errors.phone) {
      phoneInput.style.border = "2px solid red";
    }
    if (!data.message || errors.message) {
      messageInput.style.border = "2px solid red";
    }

    const errorsExist = checkErrors();
    if (errorsExist) {
      return;
    }

    if (!agreedPolicy) {
      setError("Trebuie sa fiti de acord cu politica de confidentialitate");
      return;
    } else setError("");

    sendMail(ev);
  };

  const handleContact = (ev) => {
    ev.preventDefault();
    window.open("tel:+40759513983");
  };

  const handleInputChange = (ev) => {
    handleChange(ev);
    ev.target.style.border = "1px solid #ccc";
    setSuccess(false);
  };

  const sendMail = (ev) => {
    ev.preventDefault();

    emailjs
      .sendForm(
        "service_zq6xl1s",
        "template_rrung9t",
        ev.target,
        "Pm76v8sCen0yuKP4_"
      )
      .then((result) => {
        setSuccess(true);
        history.push("/success");
      })
      .catch((err) => {
        setSuccess(true);
        setError(
          <p>
            {" "}
            {sectionData.error}{" "}
            <a
              className="contactpage__link"
              target="_blank"
              href="mailto:contact@xposed.ro"
            >
              {" "}
              contact@xposed.ro
            </a>
          </p>
        );
        console.error(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>{sectionData.hTitle}</title>
        <link rel="canonical" href="https://www.xposed.ro/contact" />
        <meta
          name="description"
          content="Contact | Agentie de Web Design - Xposed Agency"
        />
        <meta name="keywords" content="contact xposed,mail xposed" />
      </Helmet>

      <div
        className="changeLang"
        style={{ visibility: dropdownVisible ? "visible" : "hidden" }}
      >
        <DropdownComponent items={languages} selected={i18n.resolvedLanguage} />
      </div>

      <PageLanding
        src="https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2Fcontact.jpg?alt=media&token=ee9a14c1-da21-4e94-aaf2-40407356fc7b"
        title="Contact"
        languageVisible={!dropdownVisible}
      />

      <div className="contactpage">
        <div className="contactpage__section">
          <div className="section__top">
            <h3>{sectionData.subtitle}</h3>

            <div className="section__top__right">
              <h1>{splitText(sectionData.title)}</h1>

              <div
                className="pageLanding__text__separator"
                style={{ margin: "20px 0" }}
              />

              <p>{splitText(sectionData.description)}</p>
              <button
                className="contactpage__button contactpage__link"
                onClick={handleContact}
              >
                {sectionData.phone}
              </button>
              <a className="contactpage__link" href="mailto:contact@xposed.ro">
                {sectionData.email}
              </a>
              <div className="contactpage__icons">
                <a
                  className="contactpage__icons__icon"
                  href="https://www.facebook.com/xposedagency/"
                  target="_blank"
                >
                  <Icon path={mdiFacebook} size={1.2} color="#333" />
                </a>
                <a
                  className="contactpage__icons__icon"
                  href="https://www.linkedin.com/company/xposed-agency"
                  target="_blank"
                >
                  <Icon path={mdiLinkedin} size={1.2} color="#333" />
                </a>
                <a
                  className="contactpage__icons__icon"
                  href="https://www.instagram.com/xposedagency/"
                  target="_blank"
                >
                  <Icon path={mdiInstagram} size={1.2} color="#333" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contactpage__form__container">
          <form
            id="contactForm"
            className="contactpage__form"
            onSubmit={handleSubmit}
          >
            <input
              id="formName"
              name="name"
              onChange={handleInputChange}
              placeholder={form.formName}
            />
            <input
              id="formEmail"
              type="email"
              name="email"
              onChange={handleInputChange}
              placeholder={form.formEmail}
            />
            <input
              id="formPhone"
              name="phone"
              onChange={handleInputChange}
              placeholder={form.formPhone}
            />
            <input
              id="formMessage"
              name="message"
              onChange={handleInputChange}
              placeholder={form.formMessage}
            />
            <label>{form.labelA}</label>

            <div className="contactpage__form__submitarea">
              <div className="checkbox contactpage__form__input">
                <label>
                  {form.labelB}{" "}
                  <Link className="checkbox__link" to={t("policy")}>
                    <u>{form.labelC}</u>
                  </Link>
                </label>
                <input
                  name="agreedpolicy"
                  onChange={() => setAgreedPolicy(!agreedPolicy)}
                  type="checkbox"
                />
              </div>
              <button disabled={success} type="submit">
                {sectionData.form["sendMessage"]}
              </button>
            </div>
            {error && <p className="contactpage__form__error">{error}</p>}
            {success && (
              <p className="contactpage__form__success">{form.messageOk}</p>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
