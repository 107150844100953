import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyA9OPopht0UhXHl4lJJEcD-Yx82dGKAOlI",
  authDomain: "xposed-837fc.firebaseapp.com",
  projectId: "xposed-837fc",
  storageBucket: "xposed-837fc.appspot.com",
  messagingSenderId: "432019961052",
  appId: "1:432019961052:web:5daa998553e931aba23019",
  measurementId: "G-8KS1GDR745",
}

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()
const storage = firebase.storage().ref()

export { firestore, storage }
