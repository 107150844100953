import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../style/DropdownComponent.scss'
import { useHistory } from 'react-router-dom';
import { servicesPaths } from '../utils';


const useOutsideClick = (callback) => {
    const ref = React.useRef();

    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
};

const DropdownComponent = ({items, selected, serviceId}) => {

    const [isActive, setIsActive] = useState(false)
    const { t, i18n } = useTranslation()
    const [current, setCurrent] = useState(selected)
    const history = useHistory();


    const ref = useOutsideClick(() => {setIsActive(false)});

    return (
        
        <div

            ref={ref}
            className='dropdown' style={{
            position: 'absolute',
            top: 20,
        }}>
       
            <div className='dropdown-btn' onClick = {e => { e.stopPropagation();setIsActive(!isActive)}}>
                <img src={items[current]["image"]}  loading = "eager">
                </img>
            </div>
   
            {isActive && (
                <div className='dropdown-content'>
                    {
                    Object.keys(items).filter(l => l.toLowerCase() != current.toString().toLowerCase()).map((language) => (
                        <div onClick = {(e) => {
                            e.stopPropagation();
                            const index = servicesPaths[i18n.resolvedLanguage].findIndex((item) => window.location.href.includes(item))
                            console.log(index)
                            i18n.changeLanguage(language.toLowerCase())
                            setCurrent(language)
                            setIsActive(!isActive)
                            if(index != -1){
                                history.push(servicesPaths[i18n.resolvedLanguage][index])
                                window.location.reload()
                            }

                            }
                        }
                        className='dropdown-item' key={language}>
                            <img className='dropdown-icon' src={items[language]['image']} alt={'ba'} loading = "eager">
                            </img>
                        </div>
                    ))}
                </div>
            )}

        </div>
    )
}

export default DropdownComponent