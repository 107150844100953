import React from "react";
import { BriefcaseIcon } from "../svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ContactSection({ serviceId }) {
  const { t, i18n } = useTranslation();

  if (serviceId === "aplicatii-web" || serviceId === "web-applications")
    return (
      <div className="contact-new">
        <p>{t("callToActionBanner")}</p>

        <Link to="/contact">{t("button.contactus")}</Link>
      </div>
    );

  return (
    <div className="contact">
      <div className="contact__left">
        <BriefcaseIcon />

        <p>{t("idea.matters")}</p>
      </div>

      <Link to="/contact">{t("button.contactus")}</Link>
    </div>
  );
}

export default ContactSection;
