import {useSelector} from "react-redux";
import { getText } from "../redux";
import {useTranslation} from "react-i18next";
import { getServices, getService } from "../redux";

function useTextWithLanguage(selector, isBlog = false) {
    const { i18n } = useTranslation();
    return useSelector((state => getText(state, selector, i18n.resolvedLanguage, isBlog)))
}

function useMenuItems() {
    const { i18n } = useTranslation();
    return useSelector((state => getText(state, 'utils_intl', i18n.resolvedLanguage)['menuItems']))
}

function useServices(){
    const { i18n } = useTranslation();
    return useSelector((state => getServices(state, i18n.resolvedLanguage)))
}

function useService(serviceId){
    const { i18n } = useTranslation();
    return useSelector((state => getService(state, serviceId, i18n.resolvedLanguage)))
}

export { useTextWithLanguage, useMenuItems, useServices, useService };