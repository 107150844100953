import { Link } from "react-router-dom";
import "../style/TextSection.scss";
import { splitText } from "../utils";
import { useTranslation } from "react-i18next";

function TextSection({ data, approachesTitle, serviceId }) {
  const { t } = useTranslation();

  const checkURL = (url) => {
    return url === "aplicatii-web" || url === "web-applications";
  };

  return (
    <div
      className={
        data?.description == null || data?.description === ""
          ? "section__top pb-0"
          : "section__top"
      }
    >
      <h3>{data?.subtitle}</h3>

      <div
        className={`section__top__right${
          checkURL(serviceId) ? " update-w" : ""
        }`}
      >
        {approachesTitle != null && approachesTitle !== "" ? (
          <h1>{approachesTitle}</h1>
        ) : (
          <h1>{splitText(data?.title)}</h1>
        )}

        <div
          className="pageLanding__text__separator"
          style={{ margin: "20px 0" }}
        />
        {data?.description == null || data?.description === "" ? (
          <div style={{ height: 10 }} />
        ) : checkURL(serviceId) ? (
          <div className="web-app-cta">
            <p>{splitText(data?.description)}</p>

            <Link to="/contact" className="button">
              {t("button.contactus")}
            </Link>
          </div>
        ) : (
          <p>{splitText(data?.description)}</p>
        )}
      </div>
    </div>
  );
}

export default TextSection;
