export const getAdmin = state => state.admin

const initialState = JSON.parse(sessionStorage.getItem("admin") || null)

const adminReducer = (state = initialState, action) => {
  const { type, admin } = action

  switch (type) {
    case "SET_ADMIN":
      sessionStorage.setItem("admin", JSON.stringify(admin))
      return admin

    default:
      return state
  }
}

export default adminReducer
