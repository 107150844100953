import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { PageLanding, SectionsList } from "../components"
import { getArticles, getText } from "../redux"
import {useEffect} from 'react'

function Blog() {
  
  const blog = useSelector(state => getText(state, "blog", null))
  const articles = useSelector(getArticles)



  return (
    <div>
      <Helmet>
        <title>Blog | Agentie de Web Design - Xposed Agency</title>
        <link rel="canonical" href="https://www.xposed.ro/blog" />
        <meta
          name="title"
          content="Blog | Agentie de Web Design - Xposed Agency"
        />
        <meta name="description" content="Dezvoltam si lansam Aplicatii Mobile pentru iOS si Android. Noi iti transformam ideea de Aplicatia Mobila in realitate si tu vei atrage noi clienti prin intermediul acesteia. Programatorii si Designerii nostri sunt experti in Aplicatii Mobile, atat in domeniul programarii, cat in privinta UI&amp;UX."/>
        <meta name="keywords" content="blog xposed,site de prezentare,web design,dezvoltare aplicatii mobile,programator aplicatii mobile,aplicatii mobile,aplicatii iOS,aplicatii android,creare aplicatii mobile,strategie marketing aplicatii mobile,design aplicatii Android,creare aplicatii Android,dezvoltare aplicatii iOS,design aplicatii iOS,marketingul aplicatilor mobile,marketing aplicatii mobile,promovare aplicatii mobile,design aplicatii mobile"/>
      </Helmet>

      <PageLanding src={blog.mainImage} title={blog.title} />

      <SectionsList type="articles" services={articles} isBlog = {true}/>
    </div>
  )
}

export default Blog
