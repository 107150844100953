import "../style/Home.scss"
import {
	ContactSection,
	PageLanding,
	ProjectsList,
    DesignList,
	SectionsList,
} from "../components"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { getTemplate } from "../redux/templatesReducer"
import { useParams } from "react-router-dom"
import { useEffect } from "react"

const data = {
	title: "FINE DINNING",
    landingSrc: "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
    textStanga: "TEME SITE",
    textCentru: "Alege varianta de tema care ti se potriveste",
    designs: [
        {
            title:"Varianta 1",
            pictures: [
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
            ],
        },
        {
            title:"Varianta 2",
            pictures: [
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
            ],
        },
        {
            title:"Varianta 3",
            pictures: [
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
            ],
        },
        {
            title:"Varianta 4",
            pictures: [
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
                "https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/services%2FResto%20Site%2Fmain%20finedining%20new.jpg?alt=media&token=a4d2c590-1b83-406a-a1a6-96e26c3182d5",
            ],
        }
    ]
}

function Templates() {
    const { articleId } = useParams();
    const article = useSelector(state => getTemplate(state, articleId));


	return (
		<div className="home">
			<Helmet>
				<title>
					Agentie de Web Design | Dezvoltare Aplicatii Mobile - Xposed Agency
				</title>
				<link rel="canonical" href="https://www.xposed.ro" />
				<meta name="description"
							content="Expunem imaginea ta in mediul virtual: Creare Aplicatii Mobile, Aplicatii Web, CreareMagazin Online, Promovare Online, Copywriting, Strategie Marketing. "/>
				<meta name="keywords" content="creare site,creare magazin online,site de prezentare,web design,dezvoltare aplicatii mobile,programator aplicatii mobile,aplicatii mobile,aplicatii iOS,aplicatii android,creare aplicatii mobile,strategie marketing aplicatii mobile,design aplicatii Android,creare aplicatii Android,dezvoltare aplicatii iOS,design aplicatii iOS,marketingul aplicatilor mobile,marketing aplicatii mobile,promovare aplicatii mobile,design aplicatii mobile"/>
				<meta name="msapplication-TileColor" content="#da532c"/>
				<meta name="theme-color" content="#ffffff"/>
				{/*share meta tags*/}
				<meta property="og:type" content="website"/>
				<meta property="og:title" content="Agentie de Web Design | Dezvoltare Aplicatii Mobile - Xposed Agency"/>
				<meta property="og:description" content="Expunem imaginea ta in mediul virtual prin serviciile noastre de Creare Site, Creare Magazin Online, Dezvoltare Aplicatii Mobile si Promovare Online. Lucram doar cu cele mai moderne tehnologii. Iti vom livra cele mai rapide site-uri de pe piata."/>
				<meta property="og:site_name" content="Xposed Agency"/>
				<meta property="og:image" content='/main_logo.png'/>
			</Helmet>

			<PageLanding
				src={article.landingSrc}
				title={article.title}
				description={""}
				isVideo={false}
			/>

			<DesignList designs={article.designs} sectionData={{
                subtitle: "TEME SITE",
                title: "Alege varianta de tema care ti se potriveste",
                description: ""
            }} />

			<ContactSection />

			{/* <SectionsList isAspectRatio services={services} type="services" /> */}
		</div>
	)
}

export default Templates;
