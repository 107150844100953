import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { CookieBanner, Footer, Loading } from "./components";
import {
  About,
  AddArticle,
  Article,
  Blog,
  Contact,
  Home,
  PrivacyPolicy,
  Service,
  Error,
  Templates,
  Success,
} from "./pages";
import {
  fetchProjects,
  fetchServices,
  getCookie,
  fetchText,
  fetchBlog,
  fetchTemplates,
} from "./redux";
import { servicesPaths } from "./utils";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const cookie = useSelector(getCookie);

  useEffect(() => {
    const scrollToTop = () => window.scrollTo(0, 0);
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    Promise.all([
      fetchProjects(),
      fetchServices(),
      fetchText(),
      fetchBlog(),
      fetchTemplates(),
    ]).then((results) => {
      const [projects, services, text, blog, templates] = results;
      dispatch({ type: "SET_PROJECTS", projects });
      dispatch({ type: "SET_SERVICES", services });
      dispatch({ type: "SET_TEXT", text });
      dispatch({ type: "SET_BLOG", blog });
      dispatch({ type: "SET_TEMPLATES", templates });
      setInit(true);
    });
  }, [dispatch]);

  useEffect(() => {
    const handleLoading = () => {
      setLoading(true);
      setTimeout(() => setLoading(false), 600);
    };
    if (init) {
      handleLoading();
    }
  }, [pathname, init]);

  // const getPaths(() => {

  // 	servicesPaths['ro'].map(s => (
  // 		<Route key={s} path={s} component={Service} />
  // 	))
  // 	return 1
  // })

  if (loading) return <Loading />;
  return (
    <>
      <div className={"fadeOut"} />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />

        <Route path={t("policy")} component={PrivacyPolicy} />

        <Route
          path={process.env.REACT_APP_DASHBOARD_PATH}
          component={AddArticle}
        />

        <Route exact path={t("aboutUrl")} component={About} />

        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/:articleId" component={Article} />

        <Route path="/resto-site/:articleId" component={Templates} />
        {servicesPaths["ro"].map((s) => (
          <Route key={s} path={s} component={Service} />
        ))}

        {servicesPaths["en"].map((s) => (
          <Route key={s} path={s} component={Service} />
        ))}
        <Route path="/404" component={Error} />
        <Route path="/success" component={Success} />
        <Redirect from="*" to="/404" />
      </Switch>
      {pathname !== process.env.REACT_APP_DASHBOARD_PATH && <Footer />}
      {!cookie.includes("policy") && <CookieBanner />}
    </>
  );
}

export default App;
