import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mdiFacebook, mdiInstagram, mdiLinkedin } from "@mdi/js";
import Icon from "@mdi/react";
import "../style/Footer.scss";
import { useTranslation } from "react-i18next";
import {
  useTextWithLanguage,
  useServices,
} from "../hooks/useSelectorWithLanguage";
import { useLocation } from "react-router-dom";
import FooterContact from "./FooterContact";

function Footer() {
  const serviceId = useLocation().pathname.slice(1);
  const services = useServices();
  const { t, i18n } = useTranslation();

  const menuItemsList = useTextWithLanguage("utils_intl")["menuItems"].map(
    (item, index) => {
      let path = "/";
      if (item !== t("home")) path += item.toLowerCase().split(" ").join("-");
      if (!(item == "Blog" && i18n.resolvedLanguage !== "ro"))
        return (
          <Link className="footer__link" to={path} key={index}>
            {item}
          </Link>
        );
    }
  );

  const servicesList = services.map((service) => (
    <Link className="footer__link" to={service.id} key={service.id}>
      {service.title}
    </Link>
  ));

  const handleContact = (ev) => {
    ev.preventDefault();
    window.open("tel:+40759513983");
  };

  return (
    <div className="footer">
      {(serviceId === "aplicatii-web" || serviceId === "web-applications") && (
        <FooterContact />
      )}

      <div className="footer__container">
        <Link to="/">
          <img
            className="footer__logo"
            src="https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2FLogo_Footer_white.png?alt=media&token=7ea7663d-1c6e-4c71-943d-4414439ca0d7"
            alt="logo"
            loading="lazy"
          />
        </Link>

        <div className="footer__columns">
          <div className="footer__columns__item">
            <span>{t("footer.pages")}</span>
            {menuItemsList}
          </div>

          <div className="footer__columns__item">
            <span>{t("footer.services")}</span>
            {servicesList}
          </div>

          <div className="footer__columns__item">
            <span>{t("footer.contact")}</span>
            <button
              className="footer__button footer__link"
              onClick={handleContact}
            >
              +40 759 513 983
            </button>
            <Link className="footer__link" to="/contact#contactForm">
              contact@xposed.ro
            </Link>

            <a
              target="_blank"
              className="footer__link"
              href="https://www.facebook.com/xposedagency/"
            >
              facebook.com/xposedagency
            </a>
          </div>
        </div>
      </div>

      <div className="footer__copyright">Xposed Agency © 2021</div>

      <div className="footer__bottom">
        <div className="footer__policies">
          <Link
            className="footer__link footer__policies__link"
            to={t("policy")}
          >
            {t("footer.confidentiality")}
          </Link>
        </div>
        <div className="footer__icons">
          <a
            className="footer__icons__icon"
            href="https://www.facebook.com/xposedagency/"
            target="_blank"
          >
            <Icon path={mdiFacebook} size={1.2} color="#031e32" />
          </a>
          <a
            className="footer__icons__icon"
            href="https://www.linkedin.com/company/xposed-agency"
            target="_blank"
          >
            <Icon path={mdiLinkedin} size={1.2} color="#031e32" />
          </a>
          <a
            className="footer__icons__icon"
            href="https://www.instagram.com/xposedagency/"
            target="_blank"
          >
            <Icon path={mdiInstagram} size={1.2} color="#031e32" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
