import "../style/Success.scss";
import { Helmet } from "react-helmet";
import DropdownComponent from "../components/DropdownComponent";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLanguages } from "../redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function SuccessPage() {
  const { t, i18n } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const languages = useSelector((state) => getLanguages(state));
  const history = useHistory();

  const goToContact = () => {
    history.push("/contact");
  };

  useEffect(() => {
    const handleScroll = () => {
      const restoBtn = document.querySelector(".floatingBtn");
      if (restoBtn != null)
        if (window.pageYOffset >= window.innerHeight - 75) {
          restoBtn.style.top = "120px";
          setDropdownVisible(false);
        } else {
          restoBtn.style.top = "40px";
          setDropdownVisible(true);
        }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>

      <div
        className="changeLang"
        style={{ visibility: dropdownVisible ? "visible" : "hidden" }}
      >
        <DropdownComponent items={languages} selected={i18n.resolvedLanguage} />
      </div>
      <div
        className="floatingBtn"
        style={{ visibility: "visible", top: "40px" }}
        onClick={() => goToContact()}
      >
        {t("button.contactus")}
      </div>

      <div className="success">
        <div
          className="success__background"
          style={{
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2Fnot-found.png?alt=media&token=e99e9ec8-ed73-4229-b106-43dccd52f548)",
          }}
        >
          <div className="success__container">
            <span style={{ textAlign: "center" }}>{t("formSuccess")}</span>
            <Link to="/">{t("successBtn")}</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessPage;
