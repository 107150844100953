import {useState} from "react"
import Icon from "@mdi/react"
import "../style/HamburgerMenu.scss"
import { mdiMenu, mdiClose } from "@mdi/js"


const MODAL_STYLES = {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: "15px",
    zIndex: "1000",
    width: "100%",
    borderRadius: ".5em"
};
const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
};

const CONTAINER_FLUID = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    margin: "auto",
    width: "100%",
    position: "relative"
};

const IMG_FLUID = {
    width: "45%",
    minWidth: "350px",
    height: "100%",
    marginTop: "50px",
    marginBottom: "50px"
};

const Modal = ({open, children}) => {
    if (!open) return null;
    return (
        <>
            <div style={OVERLAY_STYLE}>
                <div style={MODAL_STYLES}>{children}</div>
            </div>
        </>
    );
};

const DesignItem = ({design}) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [imageIndex, setImageIndex] = useState(0)
    const [timeoutFunc, setTimeoutFunc] = useState()

    const handleClick = ev => {
        ev.preventDefault();
        setModalOpened(true);
    }

    const {pictures, modalPictures, title} = design

    const handleHover = () => {
        const changeIndex = () => {
            setImageIndex(i => {
                const nextIndex = i + 1 === pictures.length ? 0 : i + 1
                return nextIndex
            })

            setTimeoutFunc(setTimeout(changeIndex, 1200))
        }

        setTimeoutFunc(setTimeout(changeIndex, 1200))
    }

    const handleStopHover = () => {
        clearTimeout(timeoutFunc)
        setImageIndex(() => 0)
    }

    return (
        <>
            <Modal open={modalOpened}>
                <div style={CONTAINER_FLUID}>
                    <img style={IMG_FLUID} src={modalPictures[0]}/>
                    <img style={IMG_FLUID} src={modalPictures[1]}/>
                    <span className="close-btn" onClick={() => setModalOpened(false)}>
                        <Icon
                            path={mdiClose}
                            size={1.4}
                            color="#35BDF6"
                        />
                    </span>
                </div>
            </Modal>
            <a
                style={{
                    backgroundImage: `url(${pictures[imageIndex]})`,
                }}
                onClick={handleClick}
                className="projects__item"
                onMouseOver={handleHover}
                onMouseOut={handleStopHover}
            >
                <div className="projects__item__overlay">
                    <span>{title}</span>
                </div>
            </a>
        </>
    )
}

export default DesignItem

//#001018