import { Children } from "react";
import { TextSection } from "../components";
import { useTextWithLanguage } from "../hooks/useSelectorWithLanguage";
import "../style/ServicesSection.scss";
import { splitText } from "../utils";
import { useRef } from "react";
import { useState } from "react";
import { ChevronDownIcon } from "../svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { register } from "swiper/swiper-element-bundle.min.mjs";
register();

function SectionsList({
  services,
  mainDescription,
  approachesTitle,
  serviceId,
  benefits,
}) {
  const { t, i18n } = useTranslation();
  const sectionData = useTextWithLanguage("approaches");

  const servicesList = services.map((service) => {
    const { title, icon, description } = service;
    return (
      <div className="servicesSection__item">
        <img src={icon} alt={title} loading="lazy" />
        <h3 className="servicesSection__item__title">{title}</h3>
        <p className="servicesSection__item__description">
          {splitText(description)}
        </p>
      </div>
    );
  });

  const BenefitsSection = () => {
    return (
      <div className="benefits-container">
        {benefits?.map((item, index) => (
          <div key={index}>
            <img src={item.image} alt={item.title} loading="lazy" />
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    );
  };

  // FOR WEB APP SERVICE

  const sliderRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    sliderRef.current.swiper.slideNext();
  };

  const handlePrev = () => {
    sliderRef.current.swiper.slidePrev();
  };

  const handleChange = (event) => {
    const newIndex = event.detail[0].activeIndex;
    setActiveSlide(newIndex);
  };

  useEffect(() => {
    services = services.sort((a, b) => a.sort - b.sort);

    sliderRef?.current?.addEventListener("slidechange", handleChange);

    return () => {
      sliderRef?.current?.removeEventListener("slidechange", handleChange);
    };
  }, []);

  const checkURL = (url) => {
    return url === "aplicatii-web" || url === "web-applications";
  };

  return (
    <div className="servicesSection">
      <TextSection
        data={{ ...sectionData, description: mainDescription }}
        approachesTitle={approachesTitle}
        serviceId={serviceId}
      />

      {checkURL(serviceId) && (
        <div>
          <BenefitsSection />

          <TextSection data={{ title: t("ourServices") }} />
        </div>
      )}

      {checkURL(serviceId) ? (
        <div className="new-service-section">
          <swiper-container
            ref={sliderRef}
            pagination="true"
            slides-per-view="auto"
            space-between="16"
          >
            {services.map((service, index) => {
              const { title, icon, description } = service;

              return (
                <swiper-slide key={index}>
                  <div className="new-item">
                    <div className="image-wrapper">
                      <div
                        style={{ backgroundImage: `url(${icon})` }}
                        className="image"
                      />
                    </div>

                    <h3>{title}</h3>

                    <p>{splitText(description)}</p>
                  </div>
                </swiper-slide>
              );
            })}
          </swiper-container>

          <SliderPagination
            activeSlide={activeSlide}
            handleNext={handleNext}
            handlePrev={handlePrev}
            services={services}
          />
        </div>
      ) : (
        <div className="servicesSection__container">
          {Children.toArray(servicesList)}
        </div>
      )}
    </div>
  );
}

const SliderPagination = ({ activeSlide, handleNext, handlePrev }) => {
  const [nrSlides, setNrSlides] = useState(new Array(0).fill());

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 670) {
        setNrSlides(new Array(4).fill());
        return;
      }

      if (width < 1084) {
        setNrSlides(new Array(3).fill());
        return;
      }

      setNrSlides(new Array(0).fill());
      return;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="slider-buttons">
      <button type="button" onClick={handlePrev} className="slider-button">
        <div className="slider-button__prev">
          <ChevronDownIcon />
        </div>
      </button>

      {nrSlides.map((_, index) => (
        <div
          key={index}
          className={`slider-point ${index === activeSlide ? "active" : ""}`}
        ></div>
      ))}

      <button type="button" onClick={handleNext} className="slider-button">
        <div className="slider-button__next">
          <ChevronDownIcon />
        </div>
      </button>
    </div>
  );
};

export default SectionsList;
