import React, { useState, useEffect } from "react"

import "../style/PrivacyPolicy.scss"
import { PageLanding } from "../components"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import {useTextWithLanguage} from "../hooks/useSelectorWithLanguage";
import { useTranslation } from 'react-i18next'
import DropdownComponent from "../components/DropdownComponent"
import { getLanguages } from "../redux"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function PrivacyPolicy() {

	const policy = useTextWithLanguage('privacyPolicy_intl')
	const details = policy.companyDetails
	const principles = policy.principles
	const personal = policy.personal
	const dataType = policy.dataType
	const terms = policy.legalTerms
	const duration = policy.duration
	const recipients = policy.recipients
	const content = policy.content
	const gdpr = policy.gdpr
	var count = 0
	const { t, i18n } = useTranslation()
	const [dropdownVisible, setDropdownVisible] = useState(true)
	const languages = useSelector((state => getLanguages(state)))

	const getKey = () => {
		return count++
	}
	
	const history = useHistory();
	const goToContact = () => {
		history.push("/contact");
	}
	useEffect(() => {
		const handleScroll = () => {
			const restoBtn = document.querySelector(".floatingBtn")
			if(restoBtn != null)
				if (window.pageYOffset >= window.innerHeight - 75) {
					restoBtn.style.top = "120px"
					setDropdownVisible(false)
				} else {
					restoBtn.style.top = "40px"
					setDropdownVisible(true)
				}
		}
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	return (
		<>
			<Helmet>
				<title>
					{policy.hTitle}
				</title>
				<link rel="canonical" href={`https://www.xposed.ro/politica-de-confidentialitate`} />
			</Helmet>
			<div className="changeLang" style={{ visibility: dropdownVisible ? "visible" : "hidden" }}>
				<DropdownComponent items = {languages} selected = {i18n.resolvedLanguage} />
			</div>
			<div className="floatingBtn" style={{'visibility': 'visible'}} onClick={() => goToContact()}>
				{t('button.contactus')}
			</div>
			<PageLanding
				src="https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2Fpolicy.jpg?alt=media&token=3d0c1b2f-bfcc-49c7-bb89-79757bf5f47d"
				title={policy.landingTitle}
				languageVisible={!dropdownVisible}

			/>
			<div className="privacypolicy">
				<h3>{policy.title}</h3>
				<div className="privacypolicy__container">
					<p className="privacypolicy__content">
						{policy.lastUpdated}
						<br />
						<br />
						{policy.description}
					</p>
					<h5>{details.title}</h5>
					<p className="privacypolicy__content">
						{details.description}
						<br />
						<br />
						{details.contact}
						<br />
						{details.email}{" "}
						<Link className="privacypolicy__link" to="/contact#contactForm">
							<u>contact@xposed.ro</u>
						</Link>
						<br />
						{details.phone}
					</p>
					<h5>{principles.title}</h5>
					<p className="privacypolicy__content">
						{principles.description}
					</p>
					<p className="privacypolicy__content">
						{personal.title}
					</p>
					<ul>
						{ personal.details.map(
							(d) => <li key={getKey()}>{d}</li>
						)
						}
						
					</ul>
					<h5>{dataType.title}</h5>
					<p className="privacypolicy__content">
						{ dataType.description.map(
							(d) => <li key={getKey()}>{d}</li>
						)
						}
						
					</p>
					<h5>
						{terms.title}
					</h5>
					<p className="privacypolicy__content">
						{terms.description}
					</p>
					<h5>{duration.title}</h5>
					<p className="privacypolicy__content">
						{duration.description}
					</p>
					<h5>{recipients.title}</h5>
					<p className="privacypolicy__content">
						{recipients.description}
					</p>
					<ul>
						{ recipients.array.map(
							(d) => <li key={getKey()}>{d}</li>
						)
						}
					</ul>
					{ content.map(
							(d) => 
								content.indexOf(d) % 2 == 0 ? (
									<p>{d}</p>
								) : (
									<h5>{d}</h5>
								)
									
								
							
						)
					}
					
					<ul>
						{
							gdpr.map((d) => <li key={getKey()}>{d}</li>)
						}
					</ul>
					<p className="privacypolicy__end">
						{policy.endLineA}{" "}
						<Link className="privacypolicy__link" to="/contact#contactForm">
							<u>contact@xposed.ro</u>
						</Link>
						{policy.endLineB}
					</p>
				</div>
			</div>
		</>
	)
}

export default PrivacyPolicy
