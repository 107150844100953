import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectItem = ({ project, serviceId }) => {
  const history = useHistory();
  const isResto = history.location.pathname == "/resto-site";

  const { t, i18n } = useTranslation();

  const [imageIndex, setImageIndex] = useState(0);
  const [timeoutFunc, setTimeoutFunc] = useState();

  const notPublishedProjects = ["Viveduca"];

  const handleInternalLink = (e) => {
    e.preventDefault();
    history.push(project.link);
  };

  const { pictures, link, title } = project;

  const isPublished = !notPublishedProjects.includes(title);

  const handleHover = () => {
    const changeIndex = () => {
      setImageIndex((i) => {
        const nextIndex = i + 1 === pictures.length ? 0 : i + 1;
        return nextIndex;
      });

      setTimeoutFunc(setTimeout(changeIndex, 1200));
    };

    setTimeoutFunc(setTimeout(changeIndex, 1200));
  };

  const handleStopHover = () => {
    clearTimeout(timeoutFunc);
    setImageIndex(() => 0);
  };

  // Interfata pt pagina de aplicatii web
  const ProjectOverlay = (details) => {
    return (
      <div className="projects__item__overlay2">
        <div className="content">
          <h3>{details.title}</h3>
          <p className="bold">
            {i18n.language === "en" ? details.subtitleEN : details.subtitle}
          </p>
          <p>
            {i18n.language === "en"
              ? details.descriptionEN
              : details.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {project?.internal === true ? (
        <a
          style={{
            backgroundImage: `url(${pictures[imageIndex]})`,
          }}
          target="_blank"
          href="#"
          onClick={handleInternalLink}
          className={isResto ? "projects__item4" : "projects__item"}
          onMouseOver={handleHover}
          onMouseOut={handleStopHover}
        >
          {serviceId === "aplicatii-web" || serviceId === "web-applications" ? (
            ProjectOverlay(project)
          ) : (
            <div className="projects__item__overlay">
              <span>{title}</span>
            </div>
          )}
        </a>
      ) : (
        <a
          style={{
            backgroundImage: `url(${pictures[imageIndex]})`,
          }}
          target="_blank"
          href={isPublished ? link : undefined}
          // onClick={isPublished ? undefined : handleClick}
          className={isResto ? "projects__item4" : "projects__item"}
          onMouseOver={handleHover}
          onMouseOut={handleStopHover}
        >
          {serviceId === "aplicatii-web" || serviceId === "web-applications" ? (
            ProjectOverlay(project)
          ) : (
            <div className="projects__item__overlay">
              <span>{title}</span>
            </div>
          )}
          {!isPublished && (
            <div className="projects__item__overlay">
              <span>{t("unpublished")}</span>
            </div>
          )}
        </a>
      )}
    </>
  );
};

export default ProjectItem;
