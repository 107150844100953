export const BriefcaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 612 612"
    height="30"
    viewBox="0 0 612 612"
    width="30">
    <path
      d="m535.5 95.625h-114.75v-38.25c0-21.133-17.117-38.25-38.25-38.25h-153c-21.133 0-38.25 17.136-38.25 38.25v38.25h-114.75c-42.247 0-76.5 34.253-76.5 76.5v344.25c0 42.247 34.253 76.5 76.5 76.5h459c42.247 0 76.5-34.253 76.5-76.5v-344.25c0-42.247-34.253-76.5-76.5-76.5zm-306-19.125c0-10.557 8.568-19.125 19.125-19.125h114.75c10.557 0 19.125 8.568 19.125 19.125v19.125h-153zm344.25 439.875c0 21.114-17.117 38.25-38.25 38.25h-459c-21.133 0-38.25-17.136-38.25-38.25v-210.375h193.947c-1.587 6.158-2.697 12.488-2.697 19.125 0 42.247 34.253 76.5 76.5 76.5s76.5-34.253 76.5-76.5c0-6.637-1.109-12.986-2.696-19.125h193.946zm-306-191.25c0-7 2.027-13.483 5.298-19.125h65.886c3.289 5.642 5.316 12.125 5.316 19.125 0 21.133-17.136 38.25-38.25 38.25-21.133 0-38.25-17.117-38.25-38.25zm306-57.375h-535.5v-95.625c0-21.133 17.117-38.25 38.25-38.25h459c21.133 0 38.25 17.117 38.25 38.25z"
      fill="#fff"
    />
  </svg>
)

export const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-chevron-down"
    viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
  </svg>
)
