import { firestore } from "../firebase"


export const fetchProjects = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const snapshot = await firestore
				.collection("projects")
				.orderBy("sort")
				.get()

			const snapshotsData = snapshot.docs.map(doc => doc.data())

			resolve(snapshotsData)
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})
}

export const getProjects = (state, type) => {
	const { projects } = state
	if (!type) return projects
	return projects.filter(p => p.types.includes(type))
}

const projectsReducer = (state = [], action) => {
	const { type, projects } = action

	switch (type) {
		case "SET_PROJECTS":
			return projects

		default:
			return state
	}
}

export default projectsReducer
