import "../style/Error.scss"
import {Helmet} from "react-helmet";
import {useTextWithLanguage} from "../hooks/useSelectorWithLanguage";

function Error() {
	const other = useTextWithLanguage('other_intl')
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
				<meta name="googlebot" content="noindex" />
			</Helmet>
			<div className="error">
				<div
					className="error__background"
					style={{
						backgroundImage:
							"url(https://firebasestorage.googleapis.com/v0/b/xposed-837fc.appspot.com/o/predefined%2Fnot-found.png?alt=media&token=e99e9ec8-ed73-4229-b106-43dccd52f548)",
					}}
				>
					<div className="error__container">
						<span>{other.error}</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default Error
