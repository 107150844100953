import { useState } from "react"
import { useDispatch } from "react-redux"
import Icon from "@mdi/react"
import { mdiCookie } from "@mdi/js"
import { useTranslation } from 'react-i18next'
import "../style/CookieBanner.scss"

function CookieBanner() {

	const { t, i18n } = useTranslation()
	const [cookieOptions, setCookieOptions] = useState({
		necessary: true,
		preferences: false,
		statistics: false,
		marketing: false,
	})

	const dispatch = useDispatch()

	const handleAccept = () => {
		dispatch({ type: "SET_COOKIE", cookie: cookieOptions })
	}

	const handleAcceptAll = () => {
		const newOptions = {
			necessary: true,
			preferences: true,
			statistics: true,
			marketing: true,
		}

		dispatch({ type: "SET_COOKIE", cookie: newOptions })
	}

	const handleChange = ev => {
		const id = ev.target.id
		setCookieOptions(opts => ({
			...opts,
			[id]: !opts[id],
		}))
	}

	return (
		<div className="banner">
			<div className="banner__container">
				<div className="banner__text">
					<h3>
						<Icon path={mdiCookie} size={2} color="#fff" />
						{t('cookie.title')}
					</h3>
					<p>
						{t('cookie.description')}
					</p>
				</div>
				<div className="buttons">
					<div className="buttons__buttons">
						<button onClick={handleAccept}>{t('cookie.allow')}</button>
						<button onClick={handleAcceptAll}>{t('cookie.allowAll')}</button>
					</div>
					<div className="buttons__checkboxes">
						<div className="buttons__checkboxes__item">
							<input
								type="checkbox"
								id="necesarry"
								name="necesarry"
								checked={true}
								readOnly={true}
							/>
							<label htmlFor="necessary">{t('cookie.necessary')}</label>
						</div>
						<div className="buttons__checkboxes__item">
							<input
								type="checkbox"
								id="preferences"
								name="preferences"
								checked={cookieOptions.preferences}
								onChange={handleChange}
							/>
							<label htmlFor="preferences">{t('cookie.preferences')}</label>
						</div>
						<div className="buttons__checkboxes__item">
							<input
								type="checkbox"
								id="statistics"
								name="statistics"
								checked={cookieOptions.statistics}
								onChange={handleChange}
							/>
							<label htmlFor="statistics">{t('cookie.statistics')}</label>
						</div>
						<div className="buttons__checkboxes__item">
							<input
								type="checkbox"
								id="marketing"
								name="marketing"
								checked={cookieOptions.marketing}
								onChange={handleChange}
							/>
							<label htmlFor="marketing">{t('cookie.marketing')}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CookieBanner
