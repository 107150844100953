import { Fragment } from "react"


export const splitText = text => {
  if (!text) return

  const textArr = text?.split("endl")
  const length = textArr.length

  return textArr.map((text, index) => (
    <Fragment key={index}>
      {text}
      {length - 1 > index && <br />}
    </Fragment>
  ))
}


export const servicesPaths = {
  'ro': [
    "/aplicatii-web",
    "/aplicatii-mobile",
    "/magazin-online",
    "/promovare-online",
    "/copywriting",
    "/resto-site",
    "/despre-noi",
    "/contact",
    "/politica-de-confidentialitate",
  ],
  'en': [
    "/web-applications",
    "/mobile-applications",
    "/online-shop",
    "/online-promotion",
    "/copywriting",
    "/resto-site",
    "/about-us",
    "/contact",
    "/confidentiality-policy",
  ]
}

export const lngs = {
  en: { nativeName: 'English' },
  ro: { nativeName: 'Romana' },
}

export const lngsKeys = (() => {
  const arr = []
  for(var a in lngs){
    arr.push(a)
  }
  return arr
})