import Icon from "@mdi/react"
import "../style/HamburgerMenu.scss"
import { mdiMenu, mdiClose } from "@mdi/js"
import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import {useMenuItems} from "../hooks/useSelectorWithLanguage";
import { useTranslation } from 'react-i18next'

function HamburgerMenu({ main }) {
	const { t, i18n } = useTranslation()
  const [openMenu, setOpenMenu] = useState(false)
  const { pathname } = useLocation()
  const menuItems = useMenuItems()
  const handleClick = ev => {
    const restoBtn = document.querySelector(".floatingBtn");
    if(restoBtn != null)
      if(restoBtn.style.visibility == "hidden")
        restoBtn.style.visibility = 'visible'
      else
        restoBtn.style.visibility = 'hidden'
    ev.preventDefault()
    setOpenMenu(m => !m)
  }

  useEffect(() => {
  })
  const targetColor = "#35bdf6"

  const getOptions = () => {
    if(i18n.resolvedLanguage != 'ro')
      return menuItems.filter((item) => item != 'Blog')
    return menuItems
  }

  return (
    <>
      <button
        className={`hamburgerButton ${
          main ? "hamburgerButton__main" : "hamburgerButton__secondary"
        }`}
        onClick={handleClick}>
        <Icon
          path={openMenu ? mdiClose : mdiMenu}
          size={1.4}
          color={main ? "#35BDF6" : "#001018"}
        />
      </button>

      {openMenu && (
        <div className="hamburgerMenu">
          <div className="hamburgerMenu__items">
            {getOptions().map(item => {
              let path = "/"
              if (item !== t('home')){
                path += item.toLowerCase().split(" ").join("-")
              }
              
              return (
                <div>
                  <Link
                  to={path}
                  style={{ color: pathname === path ? targetColor : "white" }}
                  key={item}>
                  {item}
                  </Link>
                </div>
                
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default HamburgerMenu
