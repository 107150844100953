import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: "ro",
    resources: {
      ro: {
        translation: {
          mainTitle: {
            title:
              "Agentie de Web Design | Dezvoltare Aplicatii Mobile - Xposed Agency",
          },
          button: {
            contactus: "CONTACTEAZA-NE",
          },
          idea: {
            matters: "IDEEA TA CONTEAZA",
          },
          cookie: {
            title: "Acest site utilizează cookie-uri",
            description:
              "Folosim cookie-uri pentru a personaliza conținutul și anunțurile, pentru a oferi funcții de rețele sociale și pentru a analiza traficul. De asemenea, le oferim partenerilor de rețele sociale, de publicitate și de analize informații cu privire la modul în care folosiți site-ul nostru. Aceștia le pot combina cu alte informații oferite de dvs. sau culese în urma folosirii serviciilor lor.",
            allow: "Permite selecția",
            allowAll: "Permite toate",
            necessary: "Necesar",
            preferences: "Preferințe",
            statistics: "Statistici",
            marketing: "Marketing",
          },
          footer: {
            pages: "PAGINI",
            services: "SERVICII",
            contact: "CONTACT",
            confidentiality: "POLITICA DE CONFIDENTIALITATE",
          },
          home: "Acasa",
          language: "Limba",
          suspended_services: ["Copywriting", "Strategie de Marketing"],
          findoutMore: "AFLA MAI MULTE",
          aboutUrl: "/despre-noi",
          policy: "/politica-de-confidentialitate",
          unpublished: "Proiectul nu mai este publicat. ",
          callToActionBanner:
            "Incepe transformarea digitala a afacerii tale cu aplicatii web personalizate.",
          ourServices: "Serviciile noastre",
          formSuccess: "Formularul a fost trimis cu succes!",
          successBtn: "Inapoin pe pagina principala",
        },
      },
      en: {
        translation: {
          mainTitle: {
            title: "Web Design Agency | Mobile App Development - Xposed Agency",
          },
          button: {
            contactus: "CONTACT US",
          },
          idea: {
            matters: "YOUR IDEA MATTERS",
          },
          cookie: {
            title: "This site uses cookies",
            description:
              "We use cookies to personalize content and output, provide social networking features and analyze traffic. It also provides social media, advertising and analytics partners with information about how you use our website. They may combine it with other information you provide or collect through your use of their services.",
            allow: "Allow selection",
            allowAll: "Allow all",
            necessary: "Necessary",
            preferences: "Preferences",
            statistics: "Statistics",
            marketing: "Marketing",
          },
          footer: {
            pages: "PAGES",
            services: "SERVICES",
            contact: "CONTACT",
            confidentiality: "CONFIDENTIALITY POLICY",
          },
          home: "Home",
          language: "Language",
          suspended_services: ["Copywriting", "Marketing Strategy"],
          findoutMore: "FIND OUT MORE",
          aboutUrl: "/about-us",
          policy: "/confidentiality-policy",
          unpublished: "Project is no longer published.",
          callToActionBanner:
            "Start the digital conversion of your business with personalized web applications.",
          ourServices: "Our services",
          formSuccess: "Form submitted successfully!",
          successBtn: "Back to Home",
        },
      },
      "en-US": {
        translation: {},
      },
    },
  });
